import { useState, useMemo, useCallback } from 'react';
import { DateTime } from 'luxon';
import { Add, Search, Close } from '@mui/icons-material';
import ReactConfetti from 'react-confetti';
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Input,
  Alert,
  IconButton,
  Stack
} from '@mui/joy';
import { collection, getFirestore, addDoc } from 'firebase/firestore';
import BirthdayPerson from './BirthdayPerson';
import BirthdayDialog from './BirthdayDialog';
import { useAuthAndBirthday } from '../context/AuthAndBirthdayContext';

const Birthdays = () => {
  const { userAuth, birthdays } = useAuthAndBirthday();
  const [newBirthday, setNewBirthday] = useState(false);
  const [filter, setFilter] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);

  const getNextBirthday = useCallback(birthdayData => {
    let birthdayDate = DateTime.fromObject({
      month: birthdayData.dob.month,
      day: birthdayData.dob.day
    }).endOf('day');
    if (birthdayDate < DateTime.local().endOf('day')) {
      birthdayDate = birthdayDate.plus({ years: 1 });
    }
    return birthdayDate;
  }, []); // No dependencies, but using useCallback to keep the function reference stable

  // Use useMemo for memoizing the filtered and sorted birthdays
  const filteredAndSortedBirthdays = useMemo(() => {
    return birthdays
      .filter(
        birthday =>
          birthday.name.toLowerCase().includes(filter.toLowerCase()) ||
          birthday.notes.toLowerCase().includes(filter.toLowerCase())
      )
      .sort((b1, b2) => {
        const b1NextBirthday = getNextBirthday(b1);
        const b2NextBirthday = getNextBirthday(b2);
        return b1NextBirthday <= b2NextBirthday ? -1 : 1;
      });
  }, [birthdays, filter, getNextBirthday]);

  const handleSave = useCallback(
    birthdayData => {
      const { name, day, month, year, notes } = birthdayData;
      addDoc(collection(getFirestore(), 'users', userAuth.uid, 'birthdays'), {
        name,
        dob: {
          day: +day,
          month: +month,
          year: year ? +year : ''
        },
        notes
      }).then(() => {
        setShowConfetti(true);
      });
    },
    [userAuth.uid]
  );

  return (
    <>
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          onConfettiComplete={() => setShowConfetti(false)}
          style={{ zIndex: 11111 }}
        />
      )}
      <Grid container justifyContent="space-around" alignItems="center" my={6}>
        <Grid xs={12} md={5} lg={6}>
          <Stack gap={3}>
            <Button
              variant="solid"
              color="neutral"
              startDecorator={<Add />}
              sx={{ position: 'fixed', top: 22, right: 22, zIndex: 1111 }}
              onClick={() => setNewBirthday(true)}
            >
              Add a birthday
            </Button>
            <Card variant="outlined">
              <CardContent>
                <Input
                  placeholder="Search"
                  variant="outlined"
                  size="lg"
                  value={filter}
                  onChange={e => setFilter(e.target.value)}
                  startDecorator={<Search />}
                  endDecorator={
                    <IconButton variant="plain" onClick={() => setFilter('')}>
                      <Close />
                    </IconButton>
                  }
                  sx={{ marginBottom: 3 }}
                />
                <Typography level="body-sm" textAlign="center">
                  {filteredAndSortedBirthdays.length}{' '}
                  {filteredAndSortedBirthdays.length === 1
                    ? 'birthday'
                    : 'birthdays'}{' '}
                  found
                </Typography>
              </CardContent>
            </Card>
            {filteredAndSortedBirthdays.length === 0 &&
              birthdays.length !== 0 && (
                <Alert color="primary">
                  No birthdays found. Try adjusting your filter or add a new
                  birthday 🥳
                </Alert>
              )}
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {filteredAndSortedBirthdays.map(birthday => (
          <Grid xs={12} sm={6} md={4} key={birthday.id}>
            <BirthdayPerson birthday={birthday} userId={userAuth.uid} />
          </Grid>
        ))}
        {newBirthday && (
          <BirthdayDialog
            dialog={{
              title: 'Add a new birthday',
              message: 'Enter their details, then hit save.',
              open: newBirthday
            }}
            handleSave={handleSave}
            handleClose={() => setNewBirthday(false)}
          />
        )}
      </Grid>
    </>
  );
};

export default Birthdays;
